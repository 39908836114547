import * as React from 'react';
import Image from '../Image';

interface Props {
  /**
   * Below this breakpoint, page uses a single-column layout and sidebar will be at the bottom.
   */
  responsiveBreakpoint: 'lg' | 'xl';
  hideWhenAtBottom?: boolean;
}

const BlogSidebar: React.StatelessComponent<Props> = ({ responsiveBreakpoint, hideWhenAtBottom }) => (
  <div className={`d-${responsiveBreakpoint}-block ` + (hideWhenAtBottom ? 'd-none' : '')}>
    <div className={`pt-5 pb-5 d-none d-${responsiveBreakpoint}-block`}>
      <Image
        src='https://res.cloudinary.com/vpimg/image/upload/v1538668693/versionpress.com/undraw_book_lover_mkck.svg'
        className='img-fluid'
      />
    </div>
    <div className='slice text-center'>
      <p>Discuss &amp; subscribe on Reddit:</p>
      <a
        href='https://www.reddit.com/r/versionpress'
        className='btn btn-styled btn-base-2 btn-circle no-text-transform'
      >
        r/VersionPress
      </a>
    </div>
  </div>
);

export default BlogSidebar;
