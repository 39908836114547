import * as React from 'react';

import Link from '../components/Link';
import Helmet from 'react-helmet';
import Layout from '../layouts/Layout';
import FancyPageHeader from '../components/shared/FancyPageHeader';
import BlogSidebar from '../components/blog/BlogSidebar';

interface Props {
  pathContext: {
    group: {
      node: {
        frontmatter: {
          title: string;
          author: string;
          date: string;
        };
        fields: {
          slug: string;
        };
        timeToRead: number;
        excerpt: string;
        id: string;
      };
    }[];
    index: number;
    first: boolean;
    last: boolean;
    pageCount: number;
  };
}

// Style inspired by https://preview.webpixels.io/boomerang-v3.6.1/pages/blog/blog-technology-articles-list.html
const BlogHomePage: React.StatelessComponent<Props> = ({ pathContext: { group, first, last, index, pageCount } }) => (
  <Layout>
    <Helmet>
      <title>Blog</title>
    </Helmet>
    <FancyPageHeader title='Blog' subtitle='News from the versioned universe' className='blog-header' />
    <div className='blog slice container blogposts-list'>
      <div className='row'>
        <div className='col-lg-8 card-wrapper'>
          {group.map(({ node }) => (
            <div className='card z-depth-2-top' key={node.id}>
              <div className='row'>
                <div className='col-12'>
                  <div className='card-body'>
                    <Link className='hover-blue' to={node.fields.slug}>
                      <h2 className='heading heading-5 strong-600 line-height-1_8'>{node.frontmatter.title}</h2>
                    </Link>
                    <p className='card-text mt-3'>{node.excerpt}</p>
                    <div className='row align-items-center mt-4'>
                      <div className='col-6'>
                        <div className='block-author'>
                          <div className='author-info'>
                            <div className='author-name'>{node.frontmatter.author}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-6'>
                        <div className='text-right'>
                          <ul className='inline-links inline-links--style-2'>
                            <li>{node.timeToRead} min read</li>
                            <li>{node.frontmatter.date}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className='row'>
            <div className='col-12'>
              <div className='row justify-content-center mt-4'>
                <ul className='pagination'>
                  <li className={`page-item${first ? ' disabled' : ''}`}>
                    <Link className='page-link' to={`/blog/${index - 1 === 1 ? '' : index - 1}`}>
                      Previous
                    </Link>
                  </li>
                  {Array.from(Array(pageCount).keys()).map(page => (
                    <li className={`page-item${page + 1 === index ? ' active' : ''}`} key={page}>
                      <Link to={`/blog/${page === 0 ? '' : page + 1}`} className='page-item page-link'>
                        {page + 1}
                      </Link>
                    </li>
                  ))}
                  <li className={`page-item${last ? ' disabled' : ''}`}>
                    <Link className='page-link' to={`/blog/${index + 1}`}>
                      Next
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-3 ml-auto'>
          <BlogSidebar responsiveBreakpoint='lg' />
        </div>
      </div>
    </div>
  </Layout>
);

export default BlogHomePage;
